import * as React from 'react';
import { connect } from 'react-redux';

import {
  createStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  WithStyles,
  withStyles
} from '@material-ui/core';

import Loading from '@components/Loading';
import SearchAutocomplete from '@components/SearchAutocomplete';

import { IStore, IUserState } from '@reducers';

const styles = (theme: Theme) =>
  createStyles({
    landingContainer: {
      height: '90%'
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(12),
      [theme.breakpoints.between('sm', 'md')]: {
        paddingTop: theme.spacing(16)
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(20)
      }
    },
    subtitle: {
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.between('sm', 'md')]: {
        paddingBottom: theme.spacing(2)
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(4)
      }
    },
    search: {
      position: 'relative',

      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),

      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.breakpoints.values.sm
      },
      maxWidth: theme.breakpoints.values.sm
    }
  });

type IProps = IUserState & WithStyles<typeof styles>;

const Search: React.FC<IProps> = ({ classes, isFetching }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return isFetching ? (
    <Loading />
  ) : (
    <div className={classes.landingContainer}>
      <div className={classes.container}>
        <Typography
          variant={isExtraSmall ? 'h3' : 'h2'}
          gutterBottom
          align="center"
        >
          Discover your neighborhood
        </Typography>

        <Typography
          variant={isExtraSmall ? 'h6' : 'h5'}
          gutterBottom
          align="center"
          className={classes.subtitle}
        >
          We'll help you find your dream community.
        </Typography>
        <Paper className={classes.search}>
          <SearchAutocomplete autoSearch={true} />
        </Paper>
      </div>
    </div>
  );
};

export default withStyles(styles)(
  connect((state: IStore): IUserState => state.user)(Search)
);
