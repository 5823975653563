import { createAsyncAction } from 'typesafe-actions';

/*
 * VIEWPORT
 */

export const getCurrentPosition = createAsyncAction(
  'geolocator/GET_CURRENT_POSITION_REQUEST',
  'geolocator/GET_CURRENT_POSITION_SUCCESS',
  'geolocator/GET_CURRENT_POSITION_FAILURE'
)<void, Coordinates, Error>();
